const SIZES = ['S', 'M', 'L', 'XL', '2XL', '3XL'];

const getVariants = (colors, product) => {
  if (!colors || !product) {
    return {};
  }

  const variants = [];

  for (const size of SIZES) {
    for (const color of colors) {
      variants.push({
        option1: color,
        option2: size,
        inventory_management: 'shopify',
        inventory_quantity: 1000,
        sku: product.SKU,
        price: product.Price
      });
    }
  }

  return variants;
};

const transformObjectToShopifyProduct = product => {
  if (!product) {
    return {};
  }

  const colors = product['Color Values'].split(',').map(color => color.trim());

  return {
    title: product.Title,
    body_html: product['Description (HTML)'],
    vendor: 'teelaunch',
    images: [
      {
        src: product['Front Mockup Image Src']
      },
      {
        src: product['Back Mockup Image Src']
      }
    ],
    metafields: [
      {
        key: 'Full Front',
        value: product['Front Print File Image Src'],
        value_type: 'string',
        namespace: 'global'
      },
      {
        key: 'Full Back',
        value: product['Back Print File Image Src'] || 'NULL',
        value_type: 'string',
        namespace: 'global'
      }
    ],
    variants: getVariants(colors, product),
    options: [
      {
        name: 'Color',
        values: colors
      },
      {
        name: 'Size',
        values: SIZES
      }
    ]
  };
};

export default transformObjectToShopifyProduct;
