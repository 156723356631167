import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Form,
  FormLayout,
  Layout,
  Link,
  Page,
  Stack,
  TextField,
  Banner,
  List,
  Spinner,
  TextStyle,
  Collapsible,
  Icon
} from '@shopify/polaris';
import { CircleTickMajorMonotone } from '@shopify/polaris-icons';
import ResponsiveEmbed from 'react-responsive-embed';
import parseCsv from './utils/parseCsv';
import addProductsToShopify from './utils/addProductsToShopify';
import FileUpload from './FileUpload';
import ProductsList from './ProductsList';

const App = () => {
  const [file, setFile] = useState(false);
  const [products, setProducts] = useState({});
  const [productStatuses, setProductStatuses] = useState({});
  const [errors, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [apiKey, setApiKey] = useState('');
  const [password, setPassword] = useState('');
  const [storeName, setStoreName] = useState('');
  const [status, setStatus] = useState('');
  const [videoActive, setVideoActive] = useState(false);

  const numberOfProducts = Object.keys(products).length;
  const hasProducts = numberOfProducts > 0;
  const successfulImports = Object.values(productStatuses).filter(status => status === 'success').length;

  useEffect(() => {
    setDisabled(!hasProducts || !apiKey || !password || !storeName || status === 'importing');
  }, [hasProducts, apiKey, password, storeName, status]);

  useEffect(() => {
    parseCsv(file, setProducts, setErrors);
  }, [file]);

  const onSubmit = () => {
    setStatus('importing');
    addProductsToShopify(products, apiKey, password, storeName, setProductStatuses, setErrors, setStatus);
  };

  const description = (
    <Stack vertical>
      <p>
        Add products directly to your Shopify store from a CSV file. You can create an API key and password in the
        "Apps" section in your Shopify dashboard by creating a private app. Make sure to set the permissions for
        "Products, variants and collections” to "Read and Write".
      </p>
      <p>
        Download <Link url='http://files.teelaunch.com/Templates/sampletemplate.csv'>our sample CSV</Link> for an
        overview of all expected fields.
      </p>
      <p>
        Read the{' '}
        <Link url='https://help.shopify.com/en/manual/apps/private-apps#generate-credentials-from-the-shopify-admin'>
          Shopify API documentation
        </Link>{' '}
        for more information.
      </p>
      <Button onClick={() => setVideoActive(!videoActive)} ariaExpanded={videoActive} ariaControls='video'>
        {videoActive ? 'Hide help video' : 'Show help video'}
      </Button>
    </Stack>
  );

  const errorMessage = errors.length > 0 && (
    <Banner title={'Something went wrong:'} status='critical'>
      <List type='bullet'>
        {errors.map(({ row, message }, i) => (
          <List.Item key={i}>
            {row && <strong>Row {row}: </strong>}
            {message}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <Page title='Bulk Product Importer for teelaunch'>
      <Layout>
        <Layout.AnnotatedSection title='CSV Importer' description={description}>
          <Collapsible open={videoActive} id='video'>
            <ResponsiveEmbed src='https://www.youtube.com/embed/iR10K97GA0c' />
          </Collapsible>
          <Card sectioned>
            <Stack vertical>
              {errorMessage}
              <FileUpload file={file} onChange={file => setFile(file)} />
              <Form onSubmit={onSubmit}>
                <FormLayout>
                  <TextField
                    value={apiKey}
                    label='API Key'
                    placeholder='e0a56a75dfd926473f4cb514ad0df85f'
                    onChange={value => setApiKey(value)}
                  />

                  <TextField
                    value={password}
                    label='Password'
                    placeholder='2978293431c8a907e5281c781c6ag52c8'
                    onChange={value => setPassword(value)}
                  />

                  <TextField
                    value={storeName}
                    label='Store Name'
                    placeholder='my-store-name'
                    onChange={value => setStoreName(value)}
                    suffix='.myshopify.com'
                    helpText={
                      <span>
                        This can be found in the URL of your store dashboard, for example my-store-name.myshopify.com.
                      </span>
                    }
                  />

                  {status === 'done' ? (
                    <Stack alignment='center'>
                      <Icon source={CircleTickMajorMonotone} color='green' />
                      <TextStyle variation='positive'>All done!</TextStyle>
                    </Stack>
                  ) : (
                    <Stack alignment='center'>
                      <Button submit primary disabled={disabled}>
                        {status === 'importing' ? 'Importing...' : 'Import Products'}
                      </Button>
                      {status === 'importing' && (
                        <Stack alignment='center'>
                          <Spinner accessibilityLabel='Importing' size='small' color='sky' />
                          <TextStyle variation='subdued'>
                            {successfulImports} / {numberOfProducts} products imported
                          </TextStyle>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </FormLayout>
              </Form>
            </Stack>
          </Card>
          {hasProducts && <ProductsList products={products} productStatuses={productStatuses} />}
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
};

export default App;
