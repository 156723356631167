import { parse } from 'papaparse';

const parseCsv = (file, setProducts, setErrors) => {
  if (!file) {
    return;
  }

  parse(file, {
    header: true,
    worker: true,
    skipEmptyLines: true,
    complete: ({ data, errors }) => {
      const errorRows = errors.map(error => error.row);
      data = data.filter((_, i) => !errorRows.includes(i));

      const items = data.reduce((result, item) => {
        result[item.SKU] = item;

        return result;
      }, {});

      setProducts(items);
      setErrors(errors);
    }
  });
};

export default parseCsv;
