import React from 'react';
import { Card, DataTable, Scrollable, Thumbnail, Icon, Stack, Spinner } from '@shopify/polaris';
import { HorizontalDotsMinor, TickMinor, CancelSmallMinor } from '@shopify/polaris-icons';

const statuses = {
  success: {
    icon: TickMinor,
    color: 'green'
  },
  error: {
    icon: CancelSmallMinor,
    color: 'red'
  },
  default: {
    icon: HorizontalDotsMinor,
    color: 'sky'
  }
};

const ProductsList = ({ products, productStatuses }) => {
  const rows = Object.values(products).map(product => {
    const { Price: price, SKU: sku } = product;
    const status = productStatuses[sku] || 'default';
    const title = (
      <Stack wrap={false} alignment='center'>
        {status === 'busy' ? (
          <Spinner accessibilityLabel='Importing' size='small' color='inkLightest' />
        ) : (
          <Icon source={statuses[status].icon} color={statuses[status].color} />
        )}
        <span>{product.Title}</span>
      </Stack>
    );
    const thumbnailSrc = product['Front Mockup Image Src'] || false;
    const thumbnail = thumbnailSrc ? <Thumbnail source={thumbnailSrc} size='small' alt={product.Title} /> : '';

    return [title, thumbnail, price, sku];
  });

  return (
    <Card>
      <Scrollable shadow style={{ maxHeight: '70vh' }}>
        <DataTable
          verticalAlign='middle'
          columnContentTypes={['text', 'text', 'numeric', 'text']}
          headings={['Product', '', 'Price', 'SKU']}
          rows={rows}
        />
      </Scrollable>
    </Card>
  );
};

export default ProductsList;
