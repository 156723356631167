import { Banner, Caption, DropZone, Icon, List, Stack } from '@shopify/polaris';
import React, { useState } from 'react';

import { NoteMajorMonotone } from '@shopify/polaris-icons';

const FileUpload = ({ file, onChange }) => {
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;

  const handleDropZoneDrop = (_dropFiles, acceptedFiles, rejectedFiles) => {
    onChange(acceptedFiles.length ? acceptedFiles[0] : false);
    setRejectedFiles(rejectedFiles);
  };

  const errorMessage = hasError && (
    <Banner title='The following files couldn’t be uploaded:' status='critical'>
      <List type='bullet'>
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>{`"${file.name}" is not supported. File type must be .csv.`}</List.Item>
        ))}
      </List>
    </Banner>
  );

  const fileUpload = !file && <DropZone.FileUpload />;

  const uploadedFile = file && (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Stack vertical alignment='center' distribution='center'>
        <Icon source={NoteMajorMonotone} color='sky' />
        <div>
          {file.name} <Caption>{file.size} bytes</Caption>
        </div>
      </Stack>
    </div>
  );

  return (
    <Stack vertical>
      {errorMessage}

      <DropZone onDrop={handleDropZoneDrop} allowMultiple={false} label='CSV file' accept='text/csv'>
        {uploadedFile}
        {fileUpload}
      </DropZone>
    </Stack>
  );
};

export default FileUpload;
