import Bottleneck from 'bottleneck';
import transformObjectToShopifyProduct from './transformObjectToShopifyProduct';

// https://shopify.dev/concepts/about-apis/rate-limits
const limiter = new Bottleneck({
  minTime: 500
});

const addProductsToShopify = async (
  products,
  apiKey,
  password,
  storeName,
  setProductStatuses,
  setErrors,
  setStatus
) => {
  if (!products || !apiKey || !password || !storeName) {
    return;
  }

  let statuses = {};

  for (const product of Object.values(products)) {
    statuses = { ...statuses, [product.SKU]: 'busy' };
    setProductStatuses(statuses);

    try {
      const response = await (
        await limiter.schedule(() =>
          fetch('/.netlify/functions/call-shopify-api', {
            method: 'POST',
            body: JSON.stringify({
              apiKey,
              password,
              storeName,
              endpoint: 'products.json',
              body: { product: transformObjectToShopifyProduct(product) }
            })
          })
        )
      ).json();

      if (response.errors) {
        throw new Error(JSON.stringify(response.errors));
      }

      /**
       * Update metafields. We need to do this after creating the product because
       * we use all the variant IDs.
       */
      const metafields = response.product.variants.map(variant => ({
        key: variant.id,
        value: `${variant.sku}|${product.Title}|${variant.option1}|${variant.option2}`,
        value_type: 'string',
        namespace: 'teelaunch'
      }));

      const metaResponse = await (
        await limiter.schedule(() =>
          fetch('/.netlify/functions/call-shopify-api', {
            method: 'POST',
            body: JSON.stringify({
              apiKey,
              password,
              storeName,
              method: 'PUT',
              endpoint: `products/${response.product.id}.json`,
              body: {
                product: {
                  id: response.product.id,
                  metafields
                }
              }
            })
          })
        )
      ).json();

      if (metaResponse.errors) {
        throw new Error(JSON.stringify(metaResponse.errors));
      }

      statuses = { ...statuses, [product.SKU]: 'success' };
      setProductStatuses(statuses);
    } catch ({ message }) {
      setErrors([{ message }]);
      statuses = { ...statuses, [product.SKU]: 'error' };
      setProductStatuses(statuses);

      continue;
    }
  }

  setStatus('done');
};

export default addProductsToShopify;
